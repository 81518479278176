import {
  Text,
  Container,
  Box,
  createStyles,
  Flex,
  Grid,
  Title,
  Anchor,
  Group,
  ActionIcon,
} from "@mantine/core";
import {
  IconBrandVk,
  IconBrandYoutube,
  IconBrandTelegram,
  IconBrandWhatsapp,
} from "@tabler/icons-react";
import { VkGroupWidget } from "../vkGroup";
import React from "react";
import { Oferta } from "./Oferta";
import { PrivacyModalStore } from "../../app/PrivacyModalStore";

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  footer: {
    minWidth: 320,
    paddingBottom: theme.spacing.xl,
    backgroundColor: theme.black,

    backgroundImage:
      "repeating-linear-gradient(150deg,rgba(255,255,255,.03),rgba(255,255,255,.03) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(255,255,255,.03),rgba(255,255,255,.03) 1px,transparent 0,transparent 41px)",
    backgroundSize: "82px 47px",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
  },

  container: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  phone: {
    color: theme.white,
    fontSize: theme.fontSizes.xl,
    textDecoration: "none",

    "&:hover": {
      color: theme.colors.dark[1],
    },
  },

  afterFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    textAlign: "center",
    paddingBottom: theme.spacing.lg,
    color: theme.white,
    marginBottom: theme.spacing.lg,

    [theme.fn.smallerThan("md")]: {
      paddingBottom: 0,
    },
  },

  context: {
    [theme.fn.smallerThan("md")]: {
      // justifyContent: "center",
    },
  },
}));

export function Footer(): React.JSX.Element {
  const { classes } = useStyles();
  const open = () => PrivacyModalStore.setIsOpened(true);

  return (
    <footer className={classes.footer}>
      <Container className={classes.container} size="xl">
        <Grid justify="center">
          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Документы
            </Title>

            <Flex direction="column" className={classes.context}>
              <Oferta />
              <Anchor color="white" onClick={open}>
                Политика конфиденциальности
              </Anchor>

              <Box mt="xs" c="dimmed">
                <Text>ИП Полегаев Владимир Андреевич</Text>
                <Text>ИНН: 237306774288</Text>
                <Text>ОГРНИП 318237500349221</Text>
              </Box>

              <Box mt="xs" c="dimmed">
                <Text>ООО &#34;БОДИ КОУЧ&#34;</Text>
                <Text>ИНН: 2311299115</Text>
                <Text>ОГРН: 1192375085385</Text>
              </Box>

              <Text c="dimmed" mt="xs">
                № Образовательной лицензии:
                <br />
                Л035-01218-23/00592537
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Контакты
            </Title>

            <Flex direction="column" className={classes.context}>
              <Anchor color="white" href="mailto:info@bodycoach.pro">
                info@bodycoach.pro
              </Anchor>

              <Box mt="xs" c="dimmed">
                <Anchor color="white" href="tel:+78005516627">
                  +7 964 726-95-46
                </Anchor>
                <Text>(звонок бесплатный)</Text>
              </Box>

              <Anchor color="white" href="tel:+79952048045" mt="xs">
                8 995 204-80-45
              </Anchor>

              <Box mt="xs" c="dimmed">
                <Text>Юридический адрес:</Text>
                <Text>
                  350072, г. Краснодар, Краснодарский край, ул. Московская 118
                  корпус 1, помещ. 145, 147
                </Text>
              </Box>
            </Flex>
          </Grid.Col>

          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Соцсети
            </Title>

            <Flex direction="column" className={classes.context}>
              <Group mt="md" noWrap position="center" mb="md">
                <ActionIcon
                  component="a"
                  href="https://vk.com/bodycoachschool"
                  target="_blank"
                  color="blue"
                  size="xl"
                  variant="filled"
                  radius="xl"
                >
                  <IconBrandVk />
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href="https://www.youtube.com/channel/UCVDLKEfCv2qrwf8q1R-3dKA"
                  target="_blank"
                  color="red"
                  size="xl"
                  variant="filled"
                  radius="xl"
                >
                  <IconBrandYoutube />
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href="https://t.me/bodycoach_school"
                  target="_blank"
                  color="cyan"
                  size="xl"
                  variant="filled"
                  radius="xl"
                >
                  <IconBrandTelegram />
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href="https://api.whatsapp.com/send/?phone=79952048045&text=ID%3A+QKNSad08wcc&type=phone_number&app_absent=0"
                  target="_blank"
                  color="green"
                  size="xl"
                  variant="filled"
                  radius="xl"
                >
                  <IconBrandWhatsapp />
                </ActionIcon>
              </Group>

              <VkGroupWidget />
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>

      <Container size="xl" className={classes.afterFooter} pt={24}>
        <Flex direction="column" gap="xs" align="center">
          <Text align="center" color="dimmed">
            Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()}{" "}
            Все&nbsp;права&nbsp;защищены
          </Text>
          <Text color="dimmed">BODYCOACH.PRO</Text>
        </Flex>
      </Container>
    </footer>
  );
}
